<template>
  <li class="layout-list-item">
    <router-link
      :to="
        route.query.tab
          ? '/campaigns/' + campaign.id + '?tab=' + route.query.tab
          : '/campaigns/' + campaign.id
      "
      :class="
        selected
          ? 'layout-list-item-btn flex-container  p8 pl16 pr16 selected '
          : 'layout-list-item-btn flex-container p8 pl16 pr16 '
      "
      @click="openCampaign()"
    >
      <i
        v-if="campaign.type === 'Facebook'"
        class="lni lni-facebook-original"
      ></i>
      <i
        v-else-if="campaign.type === 'Instagram'"
        class="lni lni-instagram"
      ></i>
      <div class="flex-container flex-col flex-grow-1">
        <div class="flex-container flex-space-between-justyfy">
          <div class="layout-list-item-title nomargin pl8">
            {{ campaign.title }}
          </div>
          <i v-if="campaign?.advertiserUser?.userName" class="f12">{{
            campaign.advertiserUser.userName
          }}</i>
        </div>
        <div v-if="campaign?.campaignTags" class="f12 pl8">
          {{ campaign.campaignTags.map((t) => t.tag.name).join(", ") }}
        </div>
      </div>
    </router-link>
  </li>
</template>
<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  methods: {
    openCampaign() {
      /////emetto nascondendo o meno le colonne////
      this.emitter.emit("Campaignsmostralista", false);
      this.emitter.emit("CampaignsDettaglilista", true);
      //this.emitter.emit('CampaignsDettaglilista2',true);

      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      this.routes = ref([
        //{ name: 'Home', path: '/' },
        { name: "campagne", path: "/campaigns" },
        //{ name: usern, path: '/user/'+userid }
      ]);
      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      //userid;
      this.emitter.emit("breadcrumb", this.routes);

      /////////////////////////////////////
    },
  },

  emits: ["list-select"],
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
    selectedId: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    ///creo l'oggetto routers
    const routes = ref([
      //{ name: 'Home', path: '/' },
      { name: "campagne", path: "/campaigns" },
      //{ name: usern, path: '/user/'+userid }
    ]);

    const selected = computed(() => {
      return parseInt(props.campaign.id) === parseInt(props.selectedId);
    });
    const onSelect = () => {
      context.emit("list-select", props.campaign);
    };
    const route = useRoute();
    return {
      onSelect,
      selected,
      route,
      routes,
    };
  },
};
</script>
